import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../view/Home'),
    },
    {
        path: '/contract/:id',
        name: 'contract',
        component: () => import('../view/Contract'),
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: () => import('../view/Feedback'),
    },
    {
        path: '/feedback/success',
        name: 'feedback-success',
        component: () => import('../view/Success'),
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
