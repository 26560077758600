<template>
  <div>
    <header>
      <div>
        <b-navbar toggleable="lg" type="dark" variant="info">
          <b-navbar-brand :href="homepage_link" target="_blank">
            <b-img :src="logo" alt="Logo"/>
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>

            <b-navbar-nav class="ml-auto">

              <b-nav-item :href="contactUs" style="color:black">{{language === 'fr' ? 'CONTACT' :'CONTACT US'}}</b-nav-item>

            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </header>
  </div>
</template>

<style scoped>
.navbar.navbar-dark.bg-info {
  background-color: #fff !important;
}
</style>

<script>

export default {
  data() {
    return {
      logo: '',
      contactUs: '',
      homepage_link: '',
      language: 'en'
    }
  },
  mounted() {
    this.$root.$on('on-set-logo', async section => {
      this.logo = section.logo;
      this.contactUs = section.contactUs;
      this.homepage_link = section.homepage_link;
      this.language = section.language;
    })
  }
}

</script>
